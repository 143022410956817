.MuiAutocomplete-option{
    border-bottom: solid 1px;
    padding-bottom: 2px;
    border-color: #E0E0E0;
    width: 300;
}

.topContainer{
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.icon{
    margin-top: 10px;
    margin-left:10px;
}
#cacheIcon{
    font-size: 30px;
}
.closeIconContainer{
    display: flex; 
    justify-content: flex-end; 
    margin-top: 0px;
}
#closeIcon{
    margin-top: 0px; 
    margin-right: 0px; 
    color:red
}
.refreshIcon:hover{
    cursor: pointer;
    box-shadow: 2px 2px 4px;
    border-radius: 30px;
}
.modal{
    display: flex;
    align-items: center;
    justify-content: center;
}
.parametersCell:hover{
    cursor: pointer;
}
.paper{
    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
    padding-right: 1;
    padding-left: 15px;
    padding-top: 1;
    padding-bottom: 24px;
    align-items: center;
    justify-content: center;
}
.serverObject {
    display: flex;
    flex-wrap: wrap;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
}