.image-editor{
    display: none;
}
.image-editor-box{
    display: none;
}
.imageForTableWrapper{
  border-radius: 4px;
  padding: 5px;
  height: 100%;
  width: 100%;
}