.moduleRow:hover{
    cursor: pointer;
} 
.moduleRow{
    width: 100%;
} 
.cellStyle{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    padding: 2;
    width: 100%;
    margin: 0
}
.tutorialRow{
    border-bottom: '2px solid black'
}
.MuiAccordionSummary-content{
    justify-content: space-between;
}
.MuiAccordionSummary-content:hover{
    background-color: rgba(0, 0, 0, 0.04);
}



