.icon:hover{
    cursor: pointer;
    box-shadow: 2px 2px 4px;
    border-radius: 30px;
}
.dateContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 15;
    padding-bottom: 5
  }
  .pagination{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .button{
      margin-top: 5;
  }